var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
// Save global object in a variable
var __global__ = typeof globalThis !== "undefined" && globalThis || typeof self !== "undefined" && self || typeof _global !== "undefined" && _global;
// Create an object that extends from __global__ without the fetch function
var __globalThis__ = function () {
  function F() {
    (this || _global).fetch = false;
    (this || _global).DOMException = __global__.DOMException;
  }
  F.prototype = __global__; // Needed for feature detection on whatwg-fetch's code
  return new F();
}();
// Wraps whatwg-fetch with a function scope to hijack the global object
// "globalThis" that's going to be patched
(function (globalThis) {
  var irrelevant = function (exports) {
    var global = typeof globalThis !== "undefined" && globalThis || typeof self !== "undefined" && self || typeof global !== "undefined" && global;
    var support = {
      searchParams: "URLSearchParams" in global,
      iterable: "Symbol" in global && "iterator" in Symbol,
      blob: "FileReader" in global && "Blob" in global && function () {
        try {
          new Blob();
          return true;
        } catch (e) {
          return false;
        }
      }(),
      formData: "FormData" in global,
      arrayBuffer: "ArrayBuffer" in global
    };
    function isDataView(obj) {
      return obj && DataView.prototype.isPrototypeOf(obj);
    }
    if (support.arrayBuffer) {
      var viewClasses = ["[object Int8Array]", "[object Uint8Array]", "[object Uint8ClampedArray]", "[object Int16Array]", "[object Uint16Array]", "[object Int32Array]", "[object Uint32Array]", "[object Float32Array]", "[object Float64Array]"];
      var isArrayBufferView = ArrayBuffer.isView || function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1;
      };
    }
    function normalizeName(name) {
      if (typeof name !== "string") {
        name = String(name);
      }
      if (/[^a-z0-9\-#$%&'*+.^_`|~!]/i.test(name) || name === "") {
        throw new TypeError("Invalid character in header field name: \"" + name + "\"");
      }
      return name.toLowerCase();
    }
    function normalizeValue(value) {
      if (typeof value !== "string") {
        value = String(value);
      }
      return value;
    }

    // Build a destructive iterator for the value list
    function iteratorFor(items) {
      var iterator = {
        next: function () {
          var value = items.shift();
          return {
            done: value === undefined,
            value: value
          };
        }
      };
      if (support.iterable) {
        iterator[Symbol.iterator] = function () {
          return iterator;
        };
      }
      return iterator;
    }
    function Headers(headers) {
      (this || _global).map = {};
      if (headers instanceof Headers) {
        headers.forEach(function (value, name) {
          this.append(name, value);
        }, this || _global);
      } else if (Array.isArray(headers)) {
        headers.forEach(function (header) {
          this.append(header[0], header[1]);
        }, this || _global);
      } else if (headers) {
        Object.getOwnPropertyNames(headers).forEach(function (name) {
          this.append(name, headers[name]);
        }, this || _global);
      }
    }
    Headers.prototype.append = function (name, value) {
      name = normalizeName(name);
      value = normalizeValue(value);
      var oldValue = (this || _global).map[name];
      (this || _global).map[name] = oldValue ? oldValue + ", " + value : value;
    };
    Headers.prototype["delete"] = function (name) {
      delete (this || _global).map[normalizeName(name)];
    };
    Headers.prototype.get = function (name) {
      name = normalizeName(name);
      return this.has(name) ? (this || _global).map[name] : null;
    };
    Headers.prototype.has = function (name) {
      return (this || _global).map.hasOwnProperty(normalizeName(name));
    };
    Headers.prototype.set = function (name, value) {
      (this || _global).map[normalizeName(name)] = normalizeValue(value);
    };
    Headers.prototype.forEach = function (callback, thisArg) {
      for (var name in (this || _global).map) {
        if ((this || _global).map.hasOwnProperty(name)) {
          callback.call(thisArg, (this || _global).map[name], name, this || _global);
        }
      }
    };
    Headers.prototype.keys = function () {
      var items = [];
      this.forEach(function (value, name) {
        items.push(name);
      });
      return iteratorFor(items);
    };
    Headers.prototype.values = function () {
      var items = [];
      this.forEach(function (value) {
        items.push(value);
      });
      return iteratorFor(items);
    };
    Headers.prototype.entries = function () {
      var items = [];
      this.forEach(function (value, name) {
        items.push([name, value]);
      });
      return iteratorFor(items);
    };
    if (support.iterable) {
      Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
    }
    function consumed(body) {
      if (body.bodyUsed) {
        return Promise.reject(new TypeError("Already read"));
      }
      body.bodyUsed = true;
    }
    function fileReaderReady(reader) {
      return new Promise(function (resolve, reject) {
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function () {
          reject(reader.error);
        };
      });
    }
    function readBlobAsArrayBuffer(blob) {
      var reader = new FileReader();
      var promise = fileReaderReady(reader);
      reader.readAsArrayBuffer(blob);
      return promise;
    }
    function readBlobAsText(blob) {
      var reader = new FileReader();
      var promise = fileReaderReady(reader);
      reader.readAsText(blob);
      return promise;
    }
    function readArrayBufferAsText(buf) {
      var view = new Uint8Array(buf);
      var chars = new Array(view.length);
      for (var i = 0; i < view.length; i++) {
        chars[i] = String.fromCharCode(view[i]);
      }
      return chars.join("");
    }
    function bufferClone(buf) {
      if (buf.slice) {
        return buf.slice(0);
      } else {
        var view = new Uint8Array(buf.byteLength);
        view.set(new Uint8Array(buf));
        return view.buffer;
      }
    }
    function Body() {
      (this || _global).bodyUsed = false;
      (this || _global)._initBody = function (body) {
        /*
          fetch-mock wraps the Response object in an ES6 Proxy to
          provide useful test harness features such as flush. However, on
          ES5 browsers without fetch or Proxy support pollyfills must be used;
          the proxy-pollyfill is unable to proxy an attribute unless it exists
          on the object before the Proxy is created. This change ensures
          Response.bodyUsed exists on the instance, while maintaining the
          semantic of setting Request.bodyUsed in the constructor before
          _initBody is called.
        */
        (this || _global).bodyUsed = (this || _global).bodyUsed;
        (this || _global)._bodyInit = body;
        if (!body) {
          (this || _global)._bodyText = "";
        } else if (typeof body === "string") {
          (this || _global)._bodyText = body;
        } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
          (this || _global)._bodyBlob = body;
        } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
          (this || _global)._bodyFormData = body;
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          (this || _global)._bodyText = body.toString();
        } else if (support.arrayBuffer && support.blob && isDataView(body)) {
          (this || _global)._bodyArrayBuffer = bufferClone(body.buffer);
          // IE 10-11 can't handle a DataView body.
          (this || _global)._bodyInit = new Blob([(this || _global)._bodyArrayBuffer]);
        } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
          (this || _global)._bodyArrayBuffer = bufferClone(body);
        } else {
          (this || _global)._bodyText = body = Object.prototype.toString.call(body);
        }
        if (!(this || _global).headers.get("content-type")) {
          if (typeof body === "string") {
            (this || _global).headers.set("content-type", "text/plain;charset=UTF-8");
          } else if ((this || _global)._bodyBlob && (this || _global)._bodyBlob.type) {
            (this || _global).headers.set("content-type", (this || _global)._bodyBlob.type);
          } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
            (this || _global).headers.set("content-type", "application/x-www-form-urlencoded;charset=UTF-8");
          }
        }
      };
      if (support.blob) {
        (this || _global).blob = function () {
          var rejected = consumed(this || _global);
          if (rejected) {
            return rejected;
          }
          if ((this || _global)._bodyBlob) {
            return Promise.resolve((this || _global)._bodyBlob);
          } else if ((this || _global)._bodyArrayBuffer) {
            return Promise.resolve(new Blob([(this || _global)._bodyArrayBuffer]));
          } else if ((this || _global)._bodyFormData) {
            throw new Error("could not read FormData body as blob");
          } else {
            return Promise.resolve(new Blob([(this || _global)._bodyText]));
          }
        };
        (this || _global).arrayBuffer = function () {
          if ((this || _global)._bodyArrayBuffer) {
            var isConsumed = consumed(this || _global);
            if (isConsumed) {
              return isConsumed;
            }
            if (ArrayBuffer.isView((this || _global)._bodyArrayBuffer)) {
              return Promise.resolve((this || _global)._bodyArrayBuffer.buffer.slice((this || _global)._bodyArrayBuffer.byteOffset, (this || _global)._bodyArrayBuffer.byteOffset + (this || _global)._bodyArrayBuffer.byteLength));
            } else {
              return Promise.resolve((this || _global)._bodyArrayBuffer);
            }
          } else {
            return this.blob().then(readBlobAsArrayBuffer);
          }
        };
      }
      (this || _global).text = function () {
        var rejected = consumed(this || _global);
        if (rejected) {
          return rejected;
        }
        if ((this || _global)._bodyBlob) {
          return readBlobAsText((this || _global)._bodyBlob);
        } else if ((this || _global)._bodyArrayBuffer) {
          return Promise.resolve(readArrayBufferAsText((this || _global)._bodyArrayBuffer));
        } else if ((this || _global)._bodyFormData) {
          throw new Error("could not read FormData body as text");
        } else {
          return Promise.resolve((this || _global)._bodyText);
        }
      };
      if (support.formData) {
        (this || _global).formData = function () {
          return this.text().then(decode);
        };
      }
      (this || _global).json = function () {
        return this.text().then(JSON.parse);
      };
      return this || _global;
    }

    // HTTP methods whose capitalization should be normalized
    var methods = ["DELETE", "GET", "HEAD", "OPTIONS", "POST", "PUT"];
    function normalizeMethod(method) {
      var upcased = method.toUpperCase();
      return methods.indexOf(upcased) > -1 ? upcased : method;
    }
    function Request(input, options) {
      if (!((this || _global) instanceof Request)) {
        throw new TypeError("Please use the \"new\" operator, this DOM object constructor cannot be called as a function.");
      }
      options = options || {};
      var body = options.body;
      if (input instanceof Request) {
        if (input.bodyUsed) {
          throw new TypeError("Already read");
        }
        (this || _global).url = input.url;
        (this || _global).credentials = input.credentials;
        if (!options.headers) {
          (this || _global).headers = new Headers(input.headers);
        }
        (this || _global).method = input.method;
        (this || _global).mode = input.mode;
        (this || _global).signal = input.signal;
        if (!body && input._bodyInit != null) {
          body = input._bodyInit;
          input.bodyUsed = true;
        }
      } else {
        (this || _global).url = String(input);
      }
      (this || _global).credentials = options.credentials || (this || _global).credentials || "same-origin";
      if (options.headers || !(this || _global).headers) {
        (this || _global).headers = new Headers(options.headers);
      }
      (this || _global).method = normalizeMethod(options.method || (this || _global).method || "GET");
      (this || _global).mode = options.mode || (this || _global).mode || null;
      (this || _global).signal = options.signal || (this || _global).signal;
      (this || _global).referrer = null;
      if (((this || _global).method === "GET" || (this || _global).method === "HEAD") && body) {
        throw new TypeError("Body not allowed for GET or HEAD requests");
      }
      this._initBody(body);
      if ((this || _global).method === "GET" || (this || _global).method === "HEAD") {
        if (options.cache === "no-store" || options.cache === "no-cache") {
          // Search for a '_' parameter in the query string
          var reParamSearch = /([?&])_=[^&]*/;
          if (reParamSearch.test((this || _global).url)) {
            // If it already exists then set the value with the current time
            (this || _global).url = (this || _global).url.replace(reParamSearch, "$1_=" + new Date().getTime());
          } else {
            // Otherwise add a new '_' parameter to the end with the current time
            var reQueryString = /\?/;
            (this || _global).url += (reQueryString.test((this || _global).url) ? "&" : "?") + "_=" + new Date().getTime();
          }
        }
      }
    }
    Request.prototype.clone = function () {
      return new Request(this || _global, {
        body: (this || _global)._bodyInit
      });
    };
    function decode(body) {
      var form = new FormData();
      body.trim().split("&").forEach(function (bytes) {
        if (bytes) {
          var split = bytes.split("=");
          var name = split.shift().replace(/\+/g, " ");
          var value = split.join("=").replace(/\+/g, " ");
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
      return form;
    }
    function parseHeaders(rawHeaders) {
      var headers = new Headers();
      // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
      // https://tools.ietf.org/html/rfc7230#section-3.2
      var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
      // Avoiding split via regex to work around a common IE11 bug with the core-js 3.6.0 regex polyfill
      // https://github.com/github/fetch/issues/748
      // https://github.com/zloirock/core-js/issues/751
      preProcessedHeaders.split("\r").map(function (header) {
        return header.indexOf("\n") === 0 ? header.substr(1, header.length) : header;
      }).forEach(function (line) {
        var parts = line.split(":");
        var key = parts.shift().trim();
        if (key) {
          var value = parts.join(":").trim();
          headers.append(key, value);
        }
      });
      return headers;
    }
    Body.call(Request.prototype);
    function Response(bodyInit, options) {
      if (!((this || _global) instanceof Response)) {
        throw new TypeError("Please use the \"new\" operator, this DOM object constructor cannot be called as a function.");
      }
      if (!options) {
        options = {};
      }
      (this || _global).type = "default";
      (this || _global).status = options.status === undefined ? 200 : options.status;
      (this || _global).ok = (this || _global).status >= 200 && (this || _global).status < 300;
      (this || _global).statusText = options.statusText === undefined ? "" : "" + options.statusText;
      (this || _global).headers = new Headers(options.headers);
      (this || _global).url = options.url || "";
      this._initBody(bodyInit);
    }
    Body.call(Response.prototype);
    Response.prototype.clone = function () {
      return new Response((this || _global)._bodyInit, {
        status: (this || _global).status,
        statusText: (this || _global).statusText,
        headers: new Headers((this || _global).headers),
        url: (this || _global).url
      });
    };
    Response.error = function () {
      var response = new Response(null, {
        status: 0,
        statusText: ""
      });
      response.type = "error";
      return response;
    };
    var redirectStatuses = [301, 302, 303, 307, 308];
    Response.redirect = function (url, status) {
      if (redirectStatuses.indexOf(status) === -1) {
        throw new RangeError("Invalid status code");
      }
      return new Response(null, {
        status: status,
        headers: {
          location: url
        }
      });
    };
    exports.DOMException = global.DOMException;
    try {
      new exports.DOMException();
    } catch (err) {
      exports.DOMException = function (message, name) {
        (this || _global).message = message;
        (this || _global).name = name;
        var error = Error(message);
        (this || _global).stack = error.stack;
      };
      exports.DOMException.prototype = Object.create(Error.prototype);
      exports.DOMException.prototype.constructor = exports.DOMException;
    }
    function fetch(input, init) {
      return new Promise(function (resolve, reject) {
        var request = new Request(input, init);
        if (request.signal && request.signal.aborted) {
          return reject(new exports.DOMException("Aborted", "AbortError"));
        }
        var xhr = new XMLHttpRequest();
        function abortXhr() {
          xhr.abort();
        }
        xhr.onload = function () {
          var options = {
            status: xhr.status,
            statusText: xhr.statusText,
            headers: parseHeaders(xhr.getAllResponseHeaders() || "")
          };
          options.url = "responseURL" in xhr ? xhr.responseURL : options.headers.get("X-Request-URL");
          var body = "response" in xhr ? xhr.response : xhr.responseText;
          setTimeout(function () {
            resolve(new Response(body, options));
          }, 0);
        };
        xhr.onerror = function () {
          setTimeout(function () {
            reject(new TypeError("Network request failed"));
          }, 0);
        };
        xhr.ontimeout = function () {
          setTimeout(function () {
            reject(new TypeError("Network request failed"));
          }, 0);
        };
        xhr.onabort = function () {
          setTimeout(function () {
            reject(new exports.DOMException("Aborted", "AbortError"));
          }, 0);
        };
        function fixUrl(url) {
          try {
            return url === "" && global.location.href ? global.location.href : url;
          } catch (e) {
            return url;
          }
        }
        xhr.open(request.method, fixUrl(request.url), true);
        if (request.credentials === "include") {
          xhr.withCredentials = true;
        } else if (request.credentials === "omit") {
          xhr.withCredentials = false;
        }
        if ("responseType" in xhr) {
          if (support.blob) {
            xhr.responseType = "blob";
          } else if (support.arrayBuffer && request.headers.get("Content-Type") && request.headers.get("Content-Type").indexOf("application/octet-stream") !== -1) {
            xhr.responseType = "arraybuffer";
          }
        }
        if (init && typeof init.headers === "object" && !(init.headers instanceof Headers)) {
          Object.getOwnPropertyNames(init.headers).forEach(function (name) {
            xhr.setRequestHeader(name, normalizeValue(init.headers[name]));
          });
        } else {
          request.headers.forEach(function (value, name) {
            xhr.setRequestHeader(name, value);
          });
        }
        if (request.signal) {
          request.signal.addEventListener("abort", abortXhr);
          xhr.onreadystatechange = function () {
            // DONE (success or failure)
            if (xhr.readyState === 4) {
              request.signal.removeEventListener("abort", abortXhr);
            }
          };
        }
        xhr.send(typeof request._bodyInit === "undefined" ? null : request._bodyInit);
      });
    }
    fetch.polyfill = true;
    if (!global.fetch) {
      global.fetch = fetch;
      global.Headers = Headers;
      global.Request = Request;
      global.Response = Response;
    }
    exports.Headers = Headers;
    exports.Request = Request;
    exports.Response = Response;
    exports.fetch = fetch;
    return exports;
  }({});
})(__globalThis__);
// This is a ponyfill, so...
__globalThis__.fetch.ponyfill = true;
delete __globalThis__.fetch.polyfill;
// Choose between native implementation (__global__) or custom implementation (__globalThis__)
var ctx = __global__.fetch ? __global__ : __globalThis__;
exports = ctx.fetch; // To enable: import fetch from 'cross-fetch'
exports.default = ctx.fetch; // For TypeScript consumers without esModuleInterop.
exports.fetch = ctx.fetch; // To enable: import {fetch} from 'cross-fetch'
exports.Headers = ctx.Headers;
exports.Request = ctx.Request;
exports.Response = ctx.Response;
exports = exports;
export default exports;
export const DOMException = exports.DOMException,
  Headers = exports.Headers,
  Request = exports.Request,
  Response = exports.Response,
  fetch = exports.fetch;